import {
  NOTIFICATIONS_STORE_PUSH_ITEM_EVENT_NAME,
  NOTIFICATIONS_STORE_REMOVE_ITEM_EVENT_NAME,
  NOTIFICATIONS_STORE_CLEAR_ITEMS_EVENT_NAME
} from '../consts';
import {
  TNotificationPushItemEvent,
  TNotificationRemoveItemEvent
} from '../store/types';
import {
  pushNotification,
  setAnimation,
  clearNotifications,
  serializeNotification
} from '../store/notifications';
import { StoreRegistry, TRootState } from '../store';
import {
  selectNotifications,
  selectNotification
} from '../helpers/selectors';

export const subscribeNotificationEvents = () => {
  const callbackToPushItem = (
    event: TNotificationPushItemEvent
  ) => {
    if (!(event.detail.title || event.detail.message)) {
      throw new Error(
        'Not provided required notification properties.'
      );
    }

    if (event.detail.id) {
      const storeState =
        StoreRegistry.instance.store.getState() as TRootState;
      const notifications = selectNotifications(storeState);
      const notification = selectNotification(
        notifications,
        event.detail.id
      );

      if (notification && notification.id) {
        throw new Error(
          'Notification with same id is already there.'
        );
      }
    }

    StoreRegistry.instance.store.dispatch(
      pushNotification(serializeNotification(event.detail))
    );
  };

  const callbackToRemoveItem = (
    event: TNotificationRemoveItemEvent
  ) => {
    if (!event.detail.id) {
      throw new Error('Property id not provided.');
    }

    const storeState =
      StoreRegistry.instance.store.getState() as TRootState;
    const notifications = selectNotifications(storeState);
    const notification = selectNotification(
      notifications,
      event.detail.id
    );

    if (!notification) {
      throw new Error(
        `Notification with id = ${event.detail.id} not found.`
      );
    }

    StoreRegistry.instance.store.dispatch(
      setAnimation(event.detail.id)
    );
  };

  const callbackToClearItems = () =>
    StoreRegistry.instance.store.dispatch(
      clearNotifications()
    );

  document.addEventListener(
    NOTIFICATIONS_STORE_PUSH_ITEM_EVENT_NAME,
    callbackToPushItem
  );
  document.addEventListener(
    NOTIFICATIONS_STORE_REMOVE_ITEM_EVENT_NAME,
    callbackToRemoveItem
  );
  document.addEventListener(
    NOTIFICATIONS_STORE_CLEAR_ITEMS_EVENT_NAME,
    callbackToClearItems
  );

  return () => {
    document.removeEventListener(
      NOTIFICATIONS_STORE_PUSH_ITEM_EVENT_NAME,
      callbackToPushItem
    );
    document.removeEventListener(
      NOTIFICATIONS_STORE_REMOVE_ITEM_EVENT_NAME,
      callbackToRemoveItem
    );
    document.removeEventListener(
      NOTIFICATIONS_STORE_CLEAR_ITEMS_EVENT_NAME,
      callbackToClearItems
    );
  };
};

export default subscribeNotificationEvents;
