import React, { FC } from 'react';
import { TFragmentProps } from './types';
import styles from './Fragment.scss';

export const Fragment: FC<TFragmentProps> = ({
  children = null
}) => {
  return typeof children === 'string' ? (
    <span
      className={styles.root}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  ) : (
    <span>{children}</span>
  );
};
