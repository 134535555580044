import { combineSlices } from '@reduxjs/toolkit';
import { notificationsSlice } from './notifications';
import { dialogSlice } from './dialog';

export const rootReducer = combineSlices(
  notificationsSlice,
  dialogSlice
);

export type TRootState = ReturnType<typeof rootReducer>;
