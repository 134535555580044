import { v4 as uuidv4 } from 'uuid';
import { TNotification } from './types';

export const serializeNotification = (
  notification: TNotification
): TNotification => ({
  id: notification?.id || uuidv4(),
  title: notification.title,
  message: notification?.message,
  className: notification?.className,
  style: notification?.style,
  addonBefore: notification?.addonBefore,
  addonBottom: notification?.addonBottom,
  isTemporary: notification?.isTemporary,
  timer: notification?.timer,
  variant: notification?.variant,
  onClose: notification?.onClose
});
