// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IfxPsY6{display:flex;flex-direction:column;box-sizing:border-box;width:100%;max-width:440px;pointer-events:none}@media(max-width: 767px){.IfxPsY6{max-width:100%}}.eCy4XGS{position:fixed;z-index:1001;top:0;right:0;left:0;padding:.5em}@media(min-width: 768px){.eCy4XGS{right:0;left:initial;padding:1em}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IfxPsY6",
	"rootIsWrapped": "eCy4XGS"
};
export default ___CSS_LOADER_EXPORT___;
