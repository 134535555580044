import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import clsx from 'clsx';
import { useViewSize } from '@npm-registry/eapteka-ui';
import { useDispatch, useSelector } from 'react-redux';
import { NOTIFICATION_MAX_SHOWN_ITEMS } from '../../store/notifications/consts';
import { Notification } from '../Notification';
import {
  removeNotification,
  selectNotifications
} from '../../store/notifications';
import {
  TNotificationId,
  TNotification
} from '../../store/notifications/types';
import { TStoreDispatch } from '../../store';
import { subscribeNotificationEvents } from '../../events';
import { TNotificationsListProps } from './types';
import { getHeightStyles } from './helpers/getHeightStyles';
import styles from './NotificationsList.scss';

export const NotificationsList: FC<
  TNotificationsListProps
> = ({
  className = '',
  isWrapper = true,
  maxShownItems = NOTIFICATION_MAX_SHOWN_ITEMS
}) => {
  const { isMobile } = useViewSize();
  const [currentHeight, setCurrentHeight] = useState(0);
  const [nextHeight, setNextHeight] = useState(0);
  const [animationList, setAnimationList] = useState(
    new Map()
  );
  const dispatch: TStoreDispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const hasNotifications = useMemo(
    () => notifications && notifications.length > 0,
    [notifications]
  );
  const rootClassName: string = useMemo(
    () =>
      clsx([styles.root, className], {
        [styles.rootIsWrapped]: isWrapper
      }),
    [className, isWrapper]
  );
  const notificationList = isMobile
    ? notifications.slice(0).reverse()
    : notifications;
  const listStyles = getHeightStyles({
    currentHeight,
    nextHeight
  });

  const onRemoveNotification = useCallback(
    (id: TNotificationId) => {
      dispatch(removeNotification(id));
      setAnimationList(
        (prev) =>
          new Map(
            Array.from(prev).filter(
              (item) => item[0] !== id
            )
          )
      );
    },
    [dispatch]
  );

  const setAnimation = useCallback(
    (id: TNotificationId, animation: string) => {
      setAnimationList(
        (prev) => new Map([...prev, [id, animation]])
      );
    },
    []
  );

  useEffect(subscribeNotificationEvents, []);

  useEffect(() => {
    if (
      maxShownItems > 0 &&
      notifications?.length > maxShownItems
    ) {
      const firstNotification =
        notifications[0] as TNotification;

      if (
        firstNotification.id &&
        !animationList.get(firstNotification.id)
      ) {
        setAnimation(firstNotification?.id, 'hide');
      }
    }
  }, [
    maxShownItems,
    notifications,
    animationList,
    onRemoveNotification,
    setAnimation
  ]);

  return (
    <div className={rootClassName} style={listStyles}>
      {hasNotifications &&
        notificationList.map((item, index) => {
          const isTemporary = isMobile
            ? index === 0 && item.isTemporary
            : item.isTemporary;
          const onClose = () => {
            if (item.id) {
              onRemoveNotification(item.id);
            }

            if (typeof item.onClose === 'function') {
              item.onClose();
            }
          };
          const updateMainHeight = (
            clientHeight: number
          ) => {
            if (!isMobile && clientHeight <= 0) {
              return;
            }

            if (index === 0) {
              setCurrentHeight(clientHeight);
            }

            if (index === 1) {
              setNextHeight(clientHeight);
            }
          };
          const animation =
            item.animation ||
            animationList.get(item.id) ||
            '';
          const customProps = {
            isTemporary,
            index,
            currentHeight,
            animation,
            onClose,
            updateMainHeight,
            setAnimation
          };

          return (
            <Notification
              key={item.id}
              {...item}
              {...customProps}
            />
          );
        })}
    </div>
  );
};
