import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store/StoreRegistry';
import { NotificationsList } from './NotificationsList';
import { TNotificationsListProps } from './types';

export const NotificationsListProvider: FC<
  TNotificationsListProps
> = (transferProps) => (
  <Provider store={StoreRegistry.instance.store}>
    <NotificationsList {...transferProps} />
  </Provider>
);
