import { storesRootName } from '../consts';

export const notificationsStoreName =
  storesRootName + '/notifications';

// eslint-disable-next-line
export enum NOTIFICATION_VARIANTS {
  dark = 'dark',
  light = 'light'
}

export const NOTIFICATION_TIMEOUT = 15000;
export const NOTIFICATION_IS_TEMPORARY = true;
export const NOTIFICATION_VARIANT =
  NOTIFICATION_VARIANTS.dark;
export const NOTIFICATION_MAX_SHOWN_ITEMS = 5;
