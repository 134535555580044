import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';
import selectors from './selectors';
import { TDialogState } from './types';
import { dialogStoreName } from './consts';

const initialState: TDialogState = {
  entity: {}
};

export const dialogSlice = createSlice({
  name: dialogStoreName,
  initialState,
  selectors,
  reducers
});

export const { setDialog, clearDialog } =
  dialogSlice.actions;

export const { selectDialog } = dialogSlice.selectors;

export default dialogSlice.reducer;
