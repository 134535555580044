import { TRootState } from '../store';
import { TDialog } from '../store/dialog/types';
import { TNotification } from '../store/notifications/types';
import { TId } from '../store/types';
import { notificationsStoreName } from '../store/notifications/consts';
import { dialogStoreName } from '../store/dialog/consts';

export const selectNotifications = (
  state: TRootState
): TNotification[] | [] =>
  state[notificationsStoreName].entities;

export const selectNotification = (
  notifications: TNotification[],
  id: TId
) =>
  notifications?.find(
    (notification) => notification.id === id
  ) || null;

export const selectDialog = (
  state: TRootState
): TDialog | {} => state[dialogStoreName].entity; // eslint-disable-line
