import { TDialog, TDialogState } from './types';

type TDialogSelectors = {
  selectDialog: (state: TDialogState) => TDialog;
};

const selectDialog = (state: TDialogState) =>
  state.entity || {};

export default {
  selectDialog
} as TDialogSelectors;
