import { StoreRegistry, TRootState } from '../../store';
import {
  pushNotification as pushNotificationReducer,
  serializeNotification
} from '../../store/notifications';
import { TNotification } from '../../store/notifications/types';
import {
  selectNotification,
  selectNotifications
} from '../selectors';

export const pushNotification = (data: TNotification) => {
  if (!(data.title || data.message)) {
    throw new Error(
      'Not provided required notification properties.'
    );
  }

  if (data.id) {
    const storeState =
      StoreRegistry.instance.store.getState() as TRootState;
    const notifications = selectNotifications(storeState);
    const notification = selectNotification(
      notifications,
      data.id
    );

    if (notification && notification.id) {
      throw new Error(
        'Notification with same id is already there.'
      );
    }
  }

  StoreRegistry.instance.store.dispatch(
    pushNotificationReducer(serializeNotification(data))
  );
};
