import { ReducerCreators } from '@reduxjs/toolkit';
import { serializeNotification } from './serializer';
import {
  TNotification,
  TNotificationId,
  TNotificationsStoreState
} from './types';

export default (
  create: ReducerCreators<TNotificationsStoreState>
) => ({
  pushNotification: create.reducer<TNotification>(
    (state, { payload }) => {
      state.entities.push(serializeNotification(payload));
    }
  ),
  removeNotification: create.reducer<TNotificationId>(
    (state, { payload }) => {
      state.entities = state.entities.filter(
        (item) => item.id !== payload
      );
    }
  ),
  clearNotifications: create.reducer((state) => {
    state.entities = [];
  }),
  setAnimation: create.reducer<TNotificationId>(
    (state, { payload }) => {
      state.entities = state.entities.map((item) => {
        if (item.id === payload) {
          item.animation = 'hide';
        }

        return item;
      });
    }
  )
});
