import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store/StoreRegistry';
import { Dialog } from './Dialog';
import { TDialogProps } from './types';

export const DialogProvider: FC<TDialogProps> = (
  transferProps
) => (
  <Provider store={StoreRegistry.instance.store}>
    <Dialog {...transferProps} />
  </Provider>
);
