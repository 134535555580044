import { configureStore, Tuple } from '@reduxjs/toolkit';
import { rootReducer, TRootState } from './rootReducer';
import { storeInitSideEffects } from './storeInitSideEffects';

export const configureCoreStore = (
  initialValues?: Partial<TRootState>
) => {
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialValues,
    middleware: (getDefaultMiddleware) =>
      new Tuple(
        ...getDefaultMiddleware({
          serializableCheck: false
        })
      )
  });

  storeInitSideEffects();

  return store;
};

export type TStore = ReturnType<typeof configureCoreStore>;
export type TStoreDispatch = ReturnType<
  typeof configureCoreStore
>['dispatch'];
