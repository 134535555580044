import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';
import selectors from './selectors';
import { TNotificationsStateInitial } from './types';
import { notificationsStoreName } from './consts';

const initialState: TNotificationsStateInitial = {
  entities: []
};

export const notificationsSlice = createSlice({
  name: notificationsStoreName,
  initialState,
  selectors,
  reducers
});

export const {
  pushNotification,
  removeNotification,
  clearNotifications,
  setAnimation
} = notificationsSlice.actions;

export const { selectNotifications } =
  notificationsSlice.selectors;

export default notificationsSlice.reducer;
