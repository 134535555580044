import { CSSProperties } from 'react';
import { THeightProps } from '../types';

export const getHeightStyles = ({
  currentHeight,
  nextHeight
}: THeightProps): CSSProperties => {
  return {
    '--current-height': `${currentHeight}px`,
    '--next-height': `${nextHeight}px`
  } as CSSProperties;
};
