import React, { FC } from 'react';
import {
  Drawer,
  Modal,
  Button,
  Icon,
  useViewSize,
  ButtonThemes
} from '@npm-registry/eapteka-ui';
import { text } from '../../consts';
import { TDialogWrapperProps } from './types';
import styles from './Dialog.scss';

export const DialogWrapper: FC<TDialogWrapperProps> = ({
  children,
  isVisible,
  handleClose
}) => {
  const { isMobile } = useViewSize();

  return isMobile ? (
    <Drawer isExpanded={isVisible} onClose={handleClose}>
      {children}
    </Drawer>
  ) : (
    <Modal
      className={styles.modalContent}
      visible={isVisible}
      onClose={handleClose}
    >
      <Button
        aria-label={text['dialog.close']}
        className={styles.close}
        theme={ButtonThemes.transparent}
        onClick={handleClose}
      >
        <Icon name="close" />
      </Button>
      {children}
    </Modal>
  );
};
