import { ReducerCreators } from '@reduxjs/toolkit';
import { TDialog, TDialogState } from './types';

export default (create: ReducerCreators<TDialogState>) => ({
  setDialog: create.reducer<TDialog>(
    (state, { payload }) => {
      state.entity = payload;
    }
  ),
  clearDialog: create.reducer((state) => {
    state.entity = {};
  })
});
