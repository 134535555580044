// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zHfubXV{z-index:1001;display:flex;box-sizing:border-box}.kn-ztVp [class*=Modal-module-Content]{padding:0;border-radius:1.5em}.fL1DdRO,.fL1DdRO[class*=Button-module]{position:absolute;z-index:1;top:1.5em;right:1.5em;width:2.5em;max-width:2.5em;height:2.5em;padding:0;font-size:16px;color:#9199a3;background:#f6f7fa}.fL1DdRO:hover,.fL1DdRO[class*=Button-module]:hover{color:#333f48;background:#dee1eb}.Qe82ZM8{position:relative;min-height:2.5em;padding:0 1em 1em}@media(min-width: 768px){.Qe82ZM8{min-width:512px;max-width:700px;padding:1.5em}}@media(min-width: 768px){.tXFKkaq{padding-right:3.5em}}.mW\\+zQUl{display:flex;font-size:20px;line-height:1.2;color:#333f48}@media(min-width: 768px){.mW\\+zQUl{font-size:24px;line-height:1.4}}.Y1Vyxns{display:flex;margin-top:.5em;font-size:14px;line-height:1.25;color:#576875}@media(min-width: 768px){.Y1Vyxns{font-size:16px;line-height:1.5}}.HMm2Avw{padding-top:1em}.HMm2Avw button{width:100%;height:32px;font-size:16px}@media(min-width: 768px){.HMm2Avw button{height:44px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "zHfubXV",
	"modalContent": "kn-ztVp",
	"close": "fL1DdRO",
	"content": "Qe82ZM8",
	"inside": "tXFKkaq",
	"title": "mW+zQUl",
	"message": "Y1Vyxns",
	"addonBottom": "HMm2Avw"
};
export default ___CSS_LOADER_EXPORT___;
