import {
  TNotification,
  TNotificationsState
} from './types';

type TNotificationsSelectors = {
  selectNotifications: (
    state: TNotificationsState
  ) => TNotification[];
};

const selectNotifications = (state: TNotificationsState) =>
  state.entities || [];

export default {
  selectNotifications
} as TNotificationsSelectors;
