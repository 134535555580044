export const NOTIFICATIONS_STORE_PUSH_ITEM_EVENT_NAME =
  'appNotificationsPushItem';
export const NOTIFICATIONS_STORE_REMOVE_ITEM_EVENT_NAME =
  'appNotificationsRemoveItem';
export const NOTIFICATIONS_STORE_CLEAR_ITEMS_EVENT_NAME =
  'appNotificationsClearItems';
export const DIALOG_STORE_SET_DATA_EVENT_NAME =
  'appNotificationsSetDialog';
export const DIALOG_STORE_CLEAR_DATA_EVENT_NAME =
  'appNotificationsClearDialog';
export const APP_NOTIFICATIONS_INIT =
  'appNotificationsInit';
