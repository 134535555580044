import {
  DIALOG_STORE_SET_DATA_EVENT_NAME,
  DIALOG_STORE_CLEAR_DATA_EVENT_NAME
} from '../consts';
import { TDialogSetDataEvent } from '../store/types';
import { clearDialog, setDialog } from '../store/dialog';
import { StoreRegistry } from '../store';

export const subscribeDialogEvents = () => {
  const callbackToPushItem = (
    event: TDialogSetDataEvent
  ) => {
    if (!event.detail.title) {
      throw new Error(
        'Not provided required dialog properties.'
      );
    }

    StoreRegistry.instance.store.dispatch(
      setDialog(event.detail)
    );
  };

  const callbackToRemoveItem = () =>
    StoreRegistry.instance.store.dispatch(clearDialog());

  document.addEventListener(
    DIALOG_STORE_SET_DATA_EVENT_NAME,
    callbackToPushItem
  );
  document.addEventListener(
    DIALOG_STORE_CLEAR_DATA_EVENT_NAME,
    callbackToRemoveItem
  );

  return () => {
    document.removeEventListener(
      DIALOG_STORE_SET_DATA_EVENT_NAME,
      callbackToPushItem
    );
    document.removeEventListener(
      DIALOG_STORE_CLEAR_DATA_EVENT_NAME,
      callbackToRemoveItem
    );
  };
};

export default subscribeDialogEvents;
