import { StoreRegistry } from '../../store';
import { TDialog } from '../../store/dialog/types';
import { setDialog as setDialogReducer } from '../../store/dialog';

export const setDialog = (data: TDialog) => {
  if (!data.title) {
    throw new Error('Not provided required properties.');
  }

  StoreRegistry.instance.store.dispatch(
    setDialogReducer(data)
  );
};
