import React, {
  FC,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  Title,
  Typography
} from '@npm-registry/eapteka-ui';
import {
  clearDialog,
  selectDialog
} from '../../store/dialog';
import { TStoreDispatch } from '../../store';
import { subscribeDialogEvents } from '../../events';
import { DialogWrapper } from './DialogWrapper';
import Fragment from '../Fragment';
import { TDialogProps } from './types';
import styles from './Dialog.scss';

export const Dialog: FC<TDialogProps> = ({
  className,
  style
}) => {
  const dispatch: TStoreDispatch = useDispatch();
  const { title, message, addonBottom, onClose } =
    useSelector(selectDialog);
  const isVisible = useMemo(() => Boolean(title), [title]);
  const rootClassName = useMemo(
    () => clsx([styles.root, className]),
    [className]
  );

  const handleClose = useCallback(() => {
    dispatch(clearDialog());
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [dispatch, onClose]);

  useEffect(subscribeDialogEvents, []);

  return (
    <div className={rootClassName} style={style}>
      <DialogWrapper
        isVisible={isVisible}
        handleClose={handleClose}
      >
        <div className={styles.content}>
          <div className={styles.inside}>
            <Title className={styles.title} tag="h3">
              {title}
            </Title>
            {message && (
              <Typography className={styles.message}>
                <Fragment>{message}</Fragment>
              </Typography>
            )}
          </div>
          {addonBottom && (
            <div className={styles.addonBottom}>
              <Fragment>{addonBottom}</Fragment>
            </div>
          )}
        </div>
      </DialogWrapper>
    </div>
  );
};
