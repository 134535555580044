import { APP_NOTIFICATIONS_INIT } from '../../consts';

export const subscribeInitApp = (
  eventListener: () => void
) => {
  document.addEventListener(
    APP_NOTIFICATIONS_INIT,
    eventListener
  );

  return () => {
    document.removeEventListener(
      APP_NOTIFICATIONS_INIT,
      eventListener
    );
  };
};

export default subscribeInitApp;
