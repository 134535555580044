import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Icon,
  Button,
  ButtonThemes,
  Typography,
  useViewSize
} from '@npm-registry/eapteka-ui';
import clsx from 'clsx';
import { text, EMOJIS_REGEX } from '../../consts';
import {
  NOTIFICATION_TIMEOUT,
  NOTIFICATION_VARIANT,
  NOTIFICATION_IS_TEMPORARY,
  NOTIFICATION_VARIANTS
} from '../../store/notifications/consts';
import { Fragment } from '../Fragment/';
import { TNotificationProps } from './types';
import styles from './Notification.scss';

export const Notification: FC<TNotificationProps> = ({
  animation,
  id,
  className,
  title,
  message,
  style = {},
  isTemporary = NOTIFICATION_IS_TEMPORARY,
  addonBefore = null,
  addonBottom = null,
  timer = NOTIFICATION_TIMEOUT,
  variant = NOTIFICATION_VARIANT,
  index,
  currentHeight = 0,
  onClose,
  updateMainHeight,
  setAnimation
}) => {
  const { isMobile } = useViewSize();
  const ref = useRef<HTMLDivElement>(null);
  const [visibility, setVisibility] = useState('');
  const [clientHeight, setClientHeight] = useState(
    ref?.current?.clientHeight || 0
  );
  const diffHeight = useMemo(
    () =>
      currentHeight === 0
        ? 0
        : clientHeight - currentHeight,
    [clientHeight, currentHeight]
  );
  const transform = useMemo(() => {
    if (index !== 0 && diffHeight < 0 && isMobile) {
      return { transform: `translateY(${-diffHeight}px)` };
    }

    return {};
  }, [index, diffHeight, isMobile]);
  const maxHeight = useMemo(() => {
    if (index !== 0 && currentHeight > 0 && isMobile) {
      return { maxHeight: `${currentHeight}px` };
    }

    return {};
  }, [index, currentHeight, isMobile]);

  const customStyle = useMemo(
    () => ({
      ...style,
      ...transform,
      ...maxHeight
    }),
    [style, transform, maxHeight]
  );
  const rootClassName = useMemo(
    () =>
      clsx([
        styles.root,
        className,
        {
          [styles.rootHasAddonBefore]: Boolean(addonBefore),
          [styles.rootIsEmoji]:
            Boolean(addonBefore) &&
            typeof addonBefore === 'string' &&
            addonBefore.match(EMOJIS_REGEX),
          [styles.rootIsDark]:
            variant === NOTIFICATION_VARIANTS.dark,
          [styles.rootIsLight]:
            variant === NOTIFICATION_VARIANTS.light,
          [styles.rootIsHidden]:
            animation === 'hide' || visibility === 'hide',
          [styles.rootIsNext]: index === 1
        }
      ]),
    [
      visibility,
      className,
      variant,
      addonBefore,
      animation,
      index
    ]
  );

  const handleClose = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose(id);
    }
  }, [id, onClose]);

  const onClickClose = useCallback(() => {
    if (id && setAnimation) {
      setAnimation(id, 'hide');
    } else {
      setVisibility('hide');
      handleClose();
    }
  }, [id, setAnimation, handleClose]);

  const onAnimationEnd = useCallback(() => {
    if (animation === 'hide' || visibility === 'hide') {
      handleClose();
    }
  }, [visibility, animation, handleClose]);

  useEffect(() => {
    if (updateMainHeight && clientHeight) {
      updateMainHeight(clientHeight);
    }
  }, [clientHeight, updateMainHeight]);

  useEffect(() => {
    if (
      ref?.current?.clientHeight &&
      ref.current.clientHeight !== clientHeight &&
      isMobile
    ) {
      setClientHeight(ref.current.clientHeight);
    }
  }, [ref, clientHeight, isMobile]);

  useEffect(() => {
    if (isTemporary) {
      const timerRemoveNotification = setTimeout(() => {
        if (setAnimation && id) {
          setAnimation(id, 'hide');
        } else {
          setVisibility('hide');
          handleClose();
        }
      }, timer);

      return () => clearTimeout(timerRemoveNotification);
    }

    return;
  }, [isTemporary, timer, id, setAnimation, handleClose]);

  return (
    <div
      className={rootClassName}
      style={customStyle}
      ref={ref}
      onAnimationEnd={onAnimationEnd}
    >
      <div className={styles.wrapper}>
        {addonBefore && (
          <div className={styles.addonBefore}>
            <Fragment>{addonBefore}</Fragment>
          </div>
        )}
        <div className={styles.content}>
          {title && (
            <Typography
              className={styles.title}
              variant="p1"
            >
              {title}
            </Typography>
          )}
          {message && (
            <Typography
              className={styles.message}
              variant="p2"
            >
              <Fragment>{message}</Fragment>
            </Typography>
          )}
          {addonBottom && (
            <div className={styles.addonBottom}>
              <Fragment>{addonBottom}</Fragment>
            </div>
          )}
        </div>
        <div className={styles.close}>
          <Button
            className={styles.closeButton}
            onClick={onClickClose}
            theme={ButtonThemes.transparent}
            aria-label={text['notifications.close']}
          >
            <Icon
              name="close"
              className={styles.closeIcon}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};
