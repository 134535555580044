import { StoreRegistry, TRootState } from '../../store';
import { setAnimation } from '../../store/notifications';
import { TId } from '../../store/types';
import {
  selectNotification,
  selectNotifications
} from '../selectors';

export const removeNotification = (id: TId) => {
  if (!id) {
    throw new Error('Property id not provided.');
  }

  const storeState =
    StoreRegistry.instance.store.getState() as TRootState;
  const notifications = selectNotifications(storeState);
  const notification = selectNotification(
    notifications,
    id
  );

  if (!notification) {
    throw new Error(
      `Notification with id = ${id} not found.`
    );
  }

  StoreRegistry.instance.store.dispatch(setAnimation(id));
};
